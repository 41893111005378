// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { store } from '@/store'
import { fetchWithTimeout } from '@/common/shared'
import getEnv from '@/utils/env'

const searchBaseUrl = getEnv('VUE_APP_PATIENT_SEARCH_BASE_URL') || '/ehr/patient_search_v2/'

export async function search(query) {
  try {
    const fetchParams = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + store.getters.jwt,
      }
    }
    const url = `${searchBaseUrl}?query=${encodeURIComponent(query)}`
    const response = await fetchWithTimeout(url, fetchParams)
    if (!response.ok) {
      console.log(`error: status=${response.status}`)
      return []
    }
    const result = await response.json()
    if (!result || !result.results || result.results.length === 0) { return [] }
    // Translate the response to the format expected by the frontend.
    return result.results.map((r) => {
      if (r.uuid) {  // patient search v1
        const birthdate = r.date
        const id = r.value
        const parts = r.uuid.split(',')
        const family = (parts[0] || 'unknown').trim()
        const given = (parts[1] || '').trim()
        const name = `${family}, ${given}`
        const gender = r.desc
        return { birthdate, id, name, gender, family, given }
      }

      // patient search v2
      const birthdate = r.birthdate
      const id = r.MRN
      const family = r.family
      const given = r.given
      const name = `${family}, ${given}`
      const gender = r.gender
      return { birthdate, id, name, gender, family, given }
    })
  } catch (e) {
    if (e.name === 'AbortError') {
      console.log('timeout error')
    } else {
      console.log('ERROR:', e)
    }
  }
  return []
}
