// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { defaultThemeName, isThemeLight, themeColors } from '@/common/shared.js'

import { store, updateActivityTracker } from './index.js'

export const mutations = {
  addToolAtIndex(state, { tool, toolIndex }) {
    updateActivityTracker(state)
    state.session.tools.splice(toolIndex, 0, tool)
  },
  changeConfirmBeforeDeletingSession(state, confirmBeforeDeletingSession) {
    updateActivityTracker(state)
    state.profile.confirmBeforeDeletingSession = confirmBeforeDeletingSession
  },
  changeCurrentThemeName(state, newThemeName) {
    updateActivityTracker(state)
    if (!(newThemeName in themeColors)) {
      newThemeName = defaultThemeName()
    }
    state.profile.currentThemeName = newThemeName
    // Make all current theme colors and styling available to black back tools
    // through 'window.parent.ux2.themeColors' (from within iframe):
    if (!window.ux2) { window.ux2 = {} }
    window.ux2.currentThemeName = newThemeName
    window.ux2.themeColors = {
      ...themeColors[newThemeName],
      isThemeLight: !!isThemeLight[newThemeName],
    }
  },
  changeDarkTheme(state, newTheme) {
    updateActivityTracker(state)
    state.profile.darkThemeName = newTheme
  },
  changeDisplayName(state, newName) {
    updateActivityTracker(state)
    state.profile.displayName = newName
  },
  changeFeatureToggles(state, featureToggles) {
    updateActivityTracker(state)
    Object.keys(featureToggles).forEach((k) => {
      console.log(`featureToggles[${k}] = ${featureToggles[k]}`)
      state.featureToggles[k] = featureToggles[k]
    })
  },
  changeIsUniqueDisplayName(state, unique) {
    updateActivityTracker(state)
    state.profile.isUniqueDisplayName = unique
  },
  changeLanguage(state, newLanguage) {
    updateActivityTracker(state)
    state.profile.language = newLanguage
    // Make current language available to black back tools
    // through 'window.parent.ux2.language' (from within iframe):
    if (!window.ux2) { window.ux2 = {} }
    window.ux2.language = newLanguage
  },
  changeLightTheme(state, newTheme) {
    updateActivityTracker(state)
    state.profile.lightThemeName = newTheme
  },
  changeTimeZone(state, newTimeZone) {
    updateActivityTracker(state)
    state.profile.timeZone = newTimeZone
  },
  clearSessionTools(state) {
    updateActivityTracker(state)
    state.session.tools = []
  },
  createNewSession(state, session) {
    updateActivityTracker(state)
    const id = session.id
    state.profile.sessions[id] = session
  },
  deleteSession(state, sessionId) {
    updateActivityTracker(state)
    if (state.session && state.session.id === sessionId) {
      state.session.id = ''
      state.session.ownerId = ''
    }
    delete state.profile.sessions[sessionId]
  },
  deleteToolAtIndex(state, toolIndex) {
    updateActivityTracker(state)
    state.session.tools.splice(toolIndex, 1)
  },
  getSavedSession(state, { sessionOwnerId, sessionId }) {
    updateActivityTracker(state)
    state.session.ownerId = sessionOwnerId
    state.session.id = sessionId
    // updateToolQueryParams is used by BlackBoxSSRTools in order to
    // update and persist changes to a tool's payload.data.
    if (!window.ux2) { window.ux2 = {} }
    window.ux2.updateToolQueryParams = (toolId, params) => {
      store.dispatch('updateToolQueryParams', { params, sessionOwnerId, sessionId, toolId })
    }
  },
  replaceAllTools(state, newTools) {
    updateActivityTracker(state)
    state.session.tools = newTools
  },
  saveAllAvailableTools(state, obj) {
    updateActivityTracker(state)
    state.allAvailableTools = obj
  },
  saveAllUsers(state, obj) {
    updateActivityTracker(state)
    state.allUsers = obj
  },
  saveAuthResponse(state, authResponse) {
    // updateActivityTracker(state) - this is the ONE mutation that must NOT call updateActivityTracker!
    state.authResponse = authResponse
  },
  saveProfile(state, profile) {
    updateActivityTracker(state)
    if (!(profile.currentThemeName in themeColors)) {
      profile.currentThemeName = defaultThemeName()
    }
    profile.sessions ||= {}
    state.profile = profile
    // Make current language available to black back tools
    // through 'window.parent.ux2.language' (from within iframe):
    if (!window.ux2) { window.ux2 = {} }
    window.ux2.language = profile.language
    // Make all current theme colors and styling available to black back tools
    // through 'window.parent.ux2.themeColors' (from within iframe):
    window.ux2.currentThemeName = profile.currentThemeName
    window.ux2.themeColors = {
      ...themeColors[profile.currentThemeName],
      isThemeLight: !!isThemeLight[profile.currentThemeName],
    }
  },
  saveSessionTemplates(state, sessionTemplates) {
    updateActivityTracker(state)
    state.sessionTemplates = sessionTemplates
  },
  setSessionLastAccessed(state, { sessionId, lastAccessed }) {
    updateActivityTracker(state)
    state.profile.sessions[sessionId].lastAccessed = lastAccessed
  },
  setSessionLocallyAnonymized(state, { sessionId, locallyAnonymized }) {
    updateActivityTracker(state)
    state.profile.sessions[sessionId].locallyAnonymized = locallyAnonymized
    if (state.session.id === sessionId) {
      state.session.locallyAnonymized = locallyAnonymized
    }
  },
  setSessionName(state, { userId, sessionId, sessionName }) {
    updateActivityTracker(state)
    if (userId !== state.profile.id) { return }
    state.profile.sessions[sessionId].name = sessionName
  },
  setSessionOwnerName(state, { userId, sessionId, sessionOwnerName }) {
    updateActivityTracker(state)
    if (userId !== state.profile.id) { return }
    state.profile.sessions[sessionId].ownerName = sessionOwnerName
  },
  setSessionPatient(state, value) {
    updateActivityTracker(state)
    state.session.patient = value
  },
  setSessionSharing(state, { ownerId, sessionId, collaborateWithAnyoneLink, collaborators, viewerWithAnyoneLink, viewers }) {
    updateActivityTracker(state)
    if (ownerId !== state.profile.id) { return }
    state.profile.sessions[sessionId].collaborateWithAnyoneLink = collaborateWithAnyoneLink
    state.profile.sessions[sessionId].collaborators = collaborators
    state.profile.sessions[sessionId].viewerWithAnyoneLink = viewerWithAnyoneLink
    state.profile.sessions[sessionId].viewers = viewers
  },
  setSessionToolCount(state, { sessionId, toolCount }) {
    updateActivityTracker(state)
    if (!(sessionId in state.profile.sessions)) { return }
    state.profile.sessions[sessionId].toolCount = toolCount
  },
  showSystemToast(state, string) {
    updateActivityTracker(state)
    state.showSystemToast = string
  },
  updateToolPlacementAtIndex(state, tool) {
    updateActivityTracker(state)
    state.session.tools[tool.toolIndex].col = tool.col
    state.session.tools[tool.toolIndex].row = tool.row
    state.session.tools[tool.toolIndex].width = tool.width
    state.session.tools[tool.toolIndex].height = tool.height
  },
  updateToolPayload(state, { payload, sessionOwnerId, sessionId, toolIndex }) {
    updateActivityTracker(state)
    if (sessionOwnerId !== state.session.ownerId || sessionId !== state.session.id) { return }
    const tool = state.session.tools[toolIndex]
    if (tool) {
      tool.payload = payload
      if (tool.onReloadTool) { tool.onReloadTool(tool.payload) }
    }
  },
}
