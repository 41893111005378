<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    :fill="modalIconColor"
    class="theme-toggle-icon"
  >
    <title>{{ tr('Change theme to:') }} {{ tr(otherTheme) }}</title>
    <path :d="pathData" />
  </svg>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { isThemeLight, themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].baseHeaderIconColor
    })

    const sunPathData = `M255.5,416.4c17.7,0,31.7,14,31.7,31.7v31.7c0,17.7-14,31.7-31.7,31.7s-31.7-14-31.7-31.7v-31.7
C223.8,430.4,237.8,416.4,255.5,416.4z M255.5,95.6c-17.7,0-31.7-14-31.7-31.7V32.2c0-17.7,14-31.7,31.7-31.7s31.7,14,31.7,31.7
v31.7C287.2,81.6,273.3,95.6,255.5,95.6z M480.3,224.3c17.7,0,31.7,14,31.7,31.7s-14,31.7-31.7,31.7h-31.7
c-17.7,0-31.7-14-31.7-31.7s14-31.7,31.7-31.7H480.3z M95.1,256c0,17.7-14,31.7-31.7,31.7H31.7C14,287.7,0,273.7,0,256
s14-31.7,31.7-31.7h31.7C81.1,224.3,95.1,238.3,95.1,256z M414.1,369.8l22.4,22.4c12.1,12.1,12.1,32.6,0,45.7
c-12.1,12.1-32.6,12.1-45.7,0l-21.4-23.3c-12.1-12.1-12.1-32.6,0-45.7C381.4,356.7,402,356.7,414.1,369.8z M97,142.2l-22.4-22.4
c-12.1-12.1-12.1-32.6,0-45.7c12.1-12.1,32.6-12.1,45.7,0l22.4,22.4c12.1,12.1,12.1,32.6,0,45.7C129.6,155.3,109.1,155.3,97,142.2z
  M414.1,142.2c-12.1,12.1-32.6,12.1-45.7,0c-12.1-12.1-12.1-32.6,0-45.7l22.4-22.4c12.1-12.1,32.6-12.1,45.7,0
c12.1,12.1,12.1,32.6,0,45.7L414.1,142.2z M97,369.8c12.1-12.1,32.6-12.1,45.7,0c12.1,12.1,12.1,32.6,0,45.7l-22.4,22.4
c-12.1,12.1-32.6,12.1-45.7,0c-12.1-12.1-12.1-32.6,0-45.7L97,369.8zM255.5,128.2c-70.9,0-128.7,57.8-128.7,128.7s57.8,128.7,128.7,128.7s128.7-57.8,128.7-128.7
S326.4,128.2,255.5,128.2z M255.5,336.2c-43.8,0-80.2-35.4-80.2-80.2s35.4-80.2,80.2-80.2s80.2,35.4,80.2,80.2
S299.4,336.2,255.5,336.2z`

    const moonPathData = `M279.135 512c78.756 0 150.982-35.804 198.844-94.775 28.27-34.831-2.558-85.722-46.249-77.401-82.348 15.683-158.272-47.268-158.272-130.792 0-48.424 26.06-92.292 67.434-115.836 38.745-22.05 28.999-80.788-15.022-88.919A257.936 257.936 0 0 0 279.135 0c-141.36 0-256 114.575-256 256 0 141.36 114.576 256 256 256zm0-464c12.985 0 25.689 1.201 38.016 3.478-54.76 31.163-91.693 90.042-91.693 157.554 0 113.848 103.641 199.2 215.252 177.944C402.574 433.964 344.366 464 279.135 464c-114.875 0-208-93.125-208-208s93.125-208 208-208z`

    const pathData = computed(() => {
      return isThemeLight[store.getters.currentThemeName] ? moonPathData : sunPathData
    })

    const otherTheme = computed(() => {
      return isThemeLight[store.getters.currentThemeName] ? 'dark' : 'light'
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      otherTheme,
      pathData,
      tr,
    }
  }
}
</script>