// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, ref, watch } from 'vue'

import { store } from '@/store'
import { refreshToolPositions } from '@/components/ui/ToolManager/rearrange.js'
import getEnv from '@/utils/env'

export default {
  setup(app, { client, publishToKey }) {
    // channel: v1/sessions/#/ (Read, Write, and Presence permissions)
    const sessionChannelKey = getEnv('VUE_APP_PUBSUB_SESSION_CHANNEL_KEY')
    const publishClient = publishToKey(sessionChannelKey)
    const sessionChannelBaseName = computed(() =>
      store.getters.sessionOwnerId && store.getters.sessionId ?
        `v1/sessions/${store.getters.sessionOwnerId}/${store.getters.sessionId}` : '')

    const subscribedTo = ref('')
    watch(sessionChannelBaseName, (newValue) => {
      if (subscribedTo.value) {
        client.unsubscribe({
          key: sessionChannelKey,
          channel: `${subscribedTo.value}/`,
        })
        subscribedTo.value = ''
      }
      if (newValue) {
        client.subscribe({
          key: sessionChannelKey,
          channel: `${newValue}/`,
        })
        subscribedTo.value = newValue
      }
    })

    const createNewSessionTools = ({ session, tools }) => {
      const savedSession = {  // Only save data needed in Session.vue.
        id: session.id,
        ownerId: session.ownerId,
        collaborateWithAnyoneLink: !!session.collaborateWithAnyoneLink,
        viewerWithAnyoneLink: !!session.viewerWithAnyoneLink,
        locallyAnonymized: !!session.locallyAnonymized,
        patient: session.patient, // used in clinician view only
        tools,
      }
      const message = JSON.stringify(savedSession)
      const channel = `v1/sessions/${session.ownerId}/${session.id}/all`
      publishClient({ channel, message })
    }

    const deleteSession = ({ ownerId, sessionId, publish }) => {
      if (!publish) { return }
      const channel = `v1/sessions/${ownerId}/${sessionId}/delete`
      publishClient({ channel, message: '' })
    }

    const publishSessionToolsAll = (tools) => {
      if (!sessionChannelBaseName.value) { return }
      const message = JSON.stringify(tools)
      const channel = `${sessionChannelBaseName.value}/tools`
      publishClient({ channel, message })
    }

    const replaceAllTools = ({ tools, publish }) => {
      if (!publish) { return }
      publishSessionToolsAll(tools)
    }

    const setSessionLocallyAnonymized = ({ ownerId, sessionId, locallyAnonymized, publish }) => {
      if (!publish) { return }
      const channel = `v1/sessions/${ownerId}/${sessionId}/setSessionLocallyAnonymized`
      const message = JSON.stringify({
        ownerId,
        sessionId,
        locallyAnonymized,
      })
      publishClient({ channel, message })
    }

    const setSessionPatient = ({ patient, publish }) => {
      if (!publish) { return }
      const message = JSON.stringify(patient)
      const channel = `${sessionChannelBaseName.value}/patient`
      publishClient({ channel, message })
    }

    const setSessionSharing = ({ ownerId, sessionId, collaborateWithAnyoneLink, collaborators, viewerWithAnyoneLink, viewers, publish }) => {
      if (!publish) { return }
      const channel = `v1/sessions/${ownerId}/${sessionId}/setSessionSharing`
      const message = JSON.stringify({
        ownerId,
        sessionId,
        collaborateWithAnyoneLink,
        collaborators,
        viewerWithAnyoneLink,
        viewers,
      })
      publishClient({ channel, message })
    }

    const updateToolPayload = ({ payload, sessionOwnerId, sessionId, toolId, publish }) => {
      if (!publish) { return }
      const channel = `v1/sessions/${sessionOwnerId}/${sessionId}/updateToolPayload`
      const message = JSON.stringify({
        payload,
        sessionOwnerId,
        sessionId,
        toolId,
      })
      publishClient({ channel, message })
    }

    const sessionBeforeActions = ref({
      'addOrUpdateTool': () => { },
      'addToolAtIndex': () => { },
      'clearSessionTools': () => { },
      'createNewSessionTools': createNewSessionTools,
      'deleteToolAtIndex': () => { },  // Ignored: always followed by 'replaceAllTools'.
      'deleteSession': deleteSession,
      'duplicateSession': () => { },
      'duplicateSessionTemplate': () => { },
      'replaceAllTools': replaceAllTools,
      'setSessionLocallyAnonymized': setSessionLocallyAnonymized,
      'setSessionPatient': setSessionPatient,
      'setSessionSharing': setSessionSharing,
      'updateToolPlacement': () => { },
      'updateToolPayload': updateToolPayload,
      'updateToolQueryParams': () => { },
    })

    const sessionAfterActions = ref({
    })

    const sessionHandlers = computed(() => {
      const obj = {}
      obj[`${sessionChannelBaseName.value}/all/`] = (msg) => {
        const session = msg.asObject()
        const newMsg = {
          ownerId: session.ownerId,
          sessionId: session.id,
          locallyAnonymized: session.locallyAnonymized,
        }
        store.dispatch('setSessionLocallyAnonymized', newMsg)
        store.dispatch('setSessionPatient', { patient: session.patient }) // used in clinicianView only
        store.dispatch('replaceAllTools', { tools: session.tools })
        refreshToolPositions()
      }
      obj[`${sessionChannelBaseName.value}/delete/`] = () => {
        store.dispatch('deleteSession', { sessionId: store.getters.sessionId })
      }
      obj[`${sessionChannelBaseName.value}/patient/`] = (msg) => {
        store.dispatch('setSessionPatient', { patient: msg.asObject() })
      }
      obj[`${sessionChannelBaseName.value}/setSessionLocallyAnonymized/`] = (msg) => {
        store.dispatch('setSessionLocallyAnonymized', msg.asObject())
      }
      obj[`${sessionChannelBaseName.value}/setSessionSharing/`] = (msg) => {
        store.dispatch('setSessionSharing', msg.asObject())
      }
      obj[`${sessionChannelBaseName.value}/setSessionViewers/`] = (msg) => {
        store.dispatch('setSessionViewers', msg.asObject())
      }
      obj[`${sessionChannelBaseName.value}/tools/`] = (msg) => {
        store.dispatch('replaceAllTools', { tools: msg.asObject() })
      }
      obj[`${sessionChannelBaseName.value}/updateToolPayload/`] = (msg) => {
        store.dispatch('updateToolPayload', msg.asObject())
      }
      return obj
    })

    return {
      sessionAfterActions,
      sessionBeforeActions,
      sessionHandlers,
    }
  }
}
