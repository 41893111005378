// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { store } from '@/store'

export function rearrangeAllTools(movingToolId, ghostCol, ghostRow, tools) {
  const ghostToolProxy = { col: ghostCol, row: ghostRow, width: 0, height: 0 }
  let orderedTools = []
  tools.forEach((tool) => {
    if (tool.id === movingToolId) {
      ghostToolProxy.width = tool.width
      ghostToolProxy.height = tool.height
      return
    }
    orderedTools.push(tool)
  })

  orderedTools.sort(compareToolPositions)
  return rearrangeRemainingTools(orderedTools, ghostToolProxy)
}

export function rearrangeRemainingTools(orderedTools, ghostToolProxy) {
  let toolChangesMade = false
  let placedTools = []
  orderedTools.forEach((tool) => {
    const { tool: updatedTool, changesMade } = toolAtIdealLocation(tool, ghostToolProxy, placedTools)
    placedTools.push(updatedTool)
    if (changesMade) { toolChangesMade = true }
  })

  return { tools: placedTools, changesMade: toolChangesMade }
}

export function copyToolPositions(newPositions) {
  newPositions.forEach((t) => {
    store.dispatch('updateToolPlacement', t)
  })
}

export function toolAtIdealLocation(tool, ghostToolProxy, placedTools) {
  let reachedObstacle = false
  let maxRow = 0
  let initialRow = tool.row
  placedTools.forEach((placedTool) => {
    tool.row = placedTool.row
    if (toolsOverlap(tool, placedTool)) {
      tool.row += placedTool.height
      reachedObstacle = true
      if (tool.row < maxRow) {
        tool.row = maxRow
      }
      maxRow = tool.row
    } else if (reachedObstacle && tool.row > maxRow) {
      tool.row = maxRow  // tetris-style move upward
    }
  })

  tool.row = maxRow

  if (toolsOverlap(ghostToolProxy, tool)) {
    tool.row = ghostToolProxy.row + ghostToolProxy.height
  }

  return { tool: tool, changesMade: tool.row !== initialRow }
}

export function compareToolPositions(a, b) {
  if (a.row < b.row || (a.row === b.row && a.col < b.col)) { return -1 }
  if (a.row === b.row && a.col === b.col) { return 0 }
  return 1
}

const toolsOverlap = function (a, b) {
  return b.col + b.width > a.col &&
    b.col < a.col + a.width &&
    b.row + b.height > a.row &&
    b.row < a.row + a.height
}

export function findTopLeftTool(tools) {
  if (tools.length === 0) { return null }
  let tool = null
  tools.forEach((t) => {
    if (!tool) { tool = t; return }
    if (t.row < tool.row) { tool = t; return }
    if (t.row === tool.row && t.col < tool.col) { tool = t; return }
  })
  return tool
}

export function refreshToolPositions() {
  const topLeftTool = findTopLeftTool(store.getters.tools)
  if (!topLeftTool) {
    store.dispatch('replaceAllTools', { tools: [], publish: true })
    return
  }
  if (topLeftTool.row !== 0) {
    topLeftTool.row = 0
  }
  let availableTools = store.getters.tools
  const isChartLaunchedPatient = store.getters.session.patient?.isChartLaunchedPatient
  if (isChartLaunchedPatient) {
    availableTools = availableTools.filter(tool => !tool.omitFromPatientChartLaunch)
  }
  const { tools } = rearrangeAllTools(topLeftTool.id, topLeftTool.col, topLeftTool.row, availableTools)
  tools.unshift(topLeftTool)
  store.dispatch('replaceAllTools', { tools, publish: true })
}
