// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import router from '@/router'
import { setupTutorial } from '@/plugins/tutorial/tutorial.js'

import { addOrUpdateTool, duplicateSessionTemplate, getSavedSession } from './session.js'
import { store } from './index.js'

export const actions = {
  addOrUpdateTool,
  addSessionSharing() { },  // handled by pubsub
  addToolAtIndex(context, { tool, toolIndex }) {
    if (!context.rootGetters.sessionId) { return }
    context.commit('addToolAtIndex', { tool, toolIndex })
  },
  changeConfirmBeforeDeletingSession(context, { confirmBeforeDeletingSession }) {
    context.commit('changeConfirmBeforeDeletingSession', confirmBeforeDeletingSession)
  },
  changeCurrentThemeName(context, { themeName }) {
    context.commit('changeCurrentThemeName', themeName)
  },
  changeDarkTheme(context, { darkThemeName }) {
    context.commit('changeDarkTheme', darkThemeName)
  },
  changeDisplayName(context, { displayName }) {
    context.commit('changeDisplayName', displayName)
  },
  changeFeatureToggles(context, { featureToggles }) {
    context.commit('changeFeatureToggles', featureToggles)
  },
  changeIsUniqueDisplayName(context, { unique }) {
    context.commit('changeIsUniqueDisplayName', unique)
  },
  changeLanguage(context, { language }) {
    context.commit('changeLanguage', language)
  },
  changeLightTheme(context, { lightThemeName }) {
    context.commit('changeLightTheme', lightThemeName)
  },
  changeTimeZone(context, { timeZone }) {
    context.commit('changeTimeZone', timeZone)
  },
  clearSessionTools(context) {
    context.commit('clearSessionTools')
  },
  createNewSession(context, { session }) {
    context.commit('createNewSession', session)
  },
  createNewSessionTools() { },  // handled by pubsub
  deleteSession(context, { sessionId }) {
    context.commit('deleteSession', sessionId)
  },
  deleteToolAtIndex(context, toolIndex) {
    context.commit('deleteToolAtIndex', toolIndex)
  },
  duplicateSessionTemplate,
  duplicateSessionTools() { }, // handled by pubsub
  getAllUsers() { },  // handled by pubsub
  getSavedSession,
  onLogin() { }, // handled by pubsub
  removeSessionSharing() { }, // handled by pubsub
  replaceAllTools(context, { tools }) {
    context.commit('replaceAllTools', tools)
  },
  routeToUrl(context, url) {
    router.push(url)
  },
  saveAllAvailableTools(context, obj) {
    context.commit('saveAllAvailableTools', obj)
  },
  saveAllUsers(context, obj) {
    context.commit('saveAllUsers', obj)
  },
  saveAuthResponse(context, authResponse) {
    context.commit('saveAuthResponse', authResponse)
  },
  saveProfile(context, { profile }) {
    if ('featureToggles' in profile) {
      console.log('setting featureToggles from profile:', profile.featureToggles)
      context.commit('changeFeatureToggles', profile.featureToggles)
    }
    context.commit('saveProfile', profile)
  },
  saveSessionTemplates(context, sessionTemplates) {
    context.commit('saveSessionTemplates', sessionTemplates)
  },
  setSessionLastAccessed(context, { ownerId, sessionId, lastAccessed }) {
    context.commit('setSessionLastAccessed', { ownerId, sessionId, lastAccessed })
  },
  setSessionLocallyAnonymized(context, { ownerId, sessionId, locallyAnonymized }) {
    context.commit('setSessionLocallyAnonymized', { ownerId, sessionId, locallyAnonymized })
  },
  setSessionName(context, { userId, sessionId, sessionName }) {
    context.commit('setSessionName', { userId, sessionId, sessionName })
  },
  setSessionOwnerName(context, { userId, sessionId, sessionOwnerName }) {
    context.commit('setSessionOwnerName', { userId, sessionId, sessionOwnerName })
  },
  setSessionPatient(context, { patient }) {
    context.commit('setSessionPatient', patient)
  },
  setSessionSharing(context, { ownerId, sessionId, collaborateWithAnyoneLink, collaborators, viewerWithAnyoneLink, viewers }) {
    // If a session is no longer being shared with this user, delete the session from their profile.
    if (ownerId !== context.rootGetters.profile.id) {
      const sharedWithMe = (
        collaborateWithAnyoneLink ||
        viewerWithAnyoneLink ||
        context.rootGetters.profile.id in collaborators ||
        context.rootGetters.profile.id in viewers
      )
      if (!sharedWithMe) {
        context.commit('deleteSession', sessionId)  // do not call context.dispatch here.
        return
      }
    }
    context.commit('setSessionSharing', { ownerId, sessionId, collaborateWithAnyoneLink, collaborators, viewerWithAnyoneLink, viewers })
  },
  setSessionToolCount(context, { ownerId, sessionId, toolCount }) {
    context.commit('setSessionToolCount', { ownerId, sessionId, toolCount })
  },
  showSystemToast(context, string) {
    context.commit('showSystemToast', string)
  },
  startTutorial(context) {
    const routeToDashboard = context.rootGetters.dashboardUrl
    const startTutorial = setupTutorial(routeToDashboard, this, context.rootGetters.profile.language)
    startTutorial()
  },
  toggleTheme(context) {
    const newThemeName = context.rootGetters.isLightMode ? context.rootGetters.profile.darkThemeName : context.rootGetters.profile.lightThemeName
    context.commit('changeCurrentThemeName', newThemeName)
  },
  updateToolPlacement(context, tool) {
    const toolIndex = context.rootGetters.toolsMapIndex[tool.id]
    const t = { ...tool, toolIndex }
    context.commit('updateToolPlacementAtIndex', t)
  },
  updateToolPayload(context, { payload, sessionOwnerId, sessionId, toolId }) {
    const toolIndex = context.rootGetters.toolsMapIndex[toolId]
    context.commit('updateToolPayload', { payload, sessionOwnerId, sessionId, toolIndex })
  },
  updateToolQueryParams(context, { params, sessionOwnerId, sessionId, toolId }) {
    const toolIndex = context.rootGetters.toolsMapIndex[toolId]
    const tool = context.rootGetters.tools[toolIndex]
    const payload = tool.payload
    payload.data = {
      ...tool.payload.data,
      ...params,
    }
    store.dispatch('updateToolPayload', { payload, sessionOwnerId, sessionId, toolId, publish: true })
  },
}
